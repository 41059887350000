<template>
  <div class="cummode" v-if="show">
    <div class="cun_inner">
  <!-- <dv-border-box-1> -->
      <div class="close nclose" @click="close">×</div>
      <div class="main_title" v-if="!itemtype && paramsData.type != 'party'" style="padding: 20px 0;">{{ title }}</div>

      <div class="box" v-if="!itemtype">
        <template v-if="lx == 'link'">
          <div class="title">{{ paramsData.elementValue.key }}</div>
          <div class="word ql-editor" style="white-space:pre-wrap;" v-html="paramsData.elementValue.articleContent"></div>
          <!-- <img class="jieimg" :src="require('@/assets/cun/bochiimg1.png')" alt="">
          <img class="jieimg" :src="require('@/assets/cun/bochiimg2.png')" alt=""> -->
        </template>
        <template v-else>
          <div v-if="paramsData.type == 'party'">
            <div class="title new_title">{{ title }}党员基本情况</div>
            <!-- 党建点击地图详细要展示分类人数 -->
            <div class="party_nums ">
              <div class="party_item"><span class="party_title">正式党员：</span>{{ paramsData.formalMember }}人</div>
              <div class="party_item"><span class="party_title">预备党员：</span>{{ paramsData.prepareMember }}人</div>
              <div class="party_item"><span class="party_title">50周年党员：</span>{{ paramsData.developMember }}人</div>
            </div>
            <div class="party_nums">
              <div class="party_item"><span class="party_title">流动党员：</span>{{ paramsData.flowMember }}人</div>
              <div class="party_item"><span class="party_title">优秀党员：</span>{{ paramsData.bedMember }}人</div>
              <div class="party_item"><span class="party_title">军队退役党员：</span>{{ paramsData.armyMember }}人</div>
            </div>
            <div class="title new_title">{{ title }}简介</div>
          </div>

          <div class="word ql-editor" style="white-space:pre-wrap;" v-html="content"></div>
          <div class="flex_start imgbox">
            <div class="img_item flex_center_align flex_center" v-if="paramsData.video">
              <video controls="controls" autoplay class="video" :src="require('@/assets/cun/' + 1 + '.mp4')"></video>
            </div>
            <div class="img_item flex_center_align flex_center" v-for="(item, index) in imgList" :key="index">
              <img :src="require('@/assets/cun/' + item + '.png')" alt="" />
            </div>
          </div>
        </template>
      </div>
      <template v-else>
        <div class="box" v-if="paramsData.type == 'gridType'">
          <div class="main_title">{{ paramsData.name }}</div>
        <div v-for="(item,index) in numsForm" :key="index">
        <div class="se_title">{{ item.areaManagementName }}</div>
            <div class="title flex_center_align flex_center">
              <div class="cun_num flex_colum flex_center">
                <div>{{ item.familyNum }}</div>
                <span>户数</span>
              </div>
              <div class="cun_num flex_colum flex_center">
                <div>{{ item.residentNum }}</div>
                <span>人口数</span>
              </div>
              <div class="cun_num flex_colum flex_center">
                <div>{{ item.flowNum }}</div>
                <span>流动人口数</span>
              </div>
            </div>
            <div class="title">详情</div>
            <div class="table_box">
              <el-table stripe :data="item.familyMaintainList" v-loading="loading" height="500">
                <el-table-column label="村/组" align="center" prop="managementFull" />
                <el-table-column label="门牌号/商铺名" align="center" prop="storehouseNumber" width="120" />
                <el-table-column label="户主" align="center" prop="famName" />
                <el-table-column label="流动人口" align="center" prop="famCountWai" />
                <el-table-column label="总人口" align="center" prop="famCount" />
                <el-table-column label="" align="center" class-name="small-padding fixed-width">
                  <template slot-scope="scope">
                    <img src="../assets/img/r-arrow.png" alt="" @click.stop="handleShow(scope.row)" style="width:30px;height:30px;cursor: pointer;" />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="box" v-else>
          <div class="main_title">{{ paramsData.name }}</div>
          <div class="title flex_center_align flex_center">
            <div class="cun_num flex_colum flex_center">
              <div>{{ numsForm.familyNum }}</div>
              <span>户数</span>
            </div>
            <div class="cun_num flex_colum flex_center">
              <div>{{ numsForm.residentNum }}</div>
              <span>人口数</span>
            </div>
            <div class="cun_num flex_colum flex_center">
              <div>{{ numsForm.flowNum }}</div>
              <span>流动人口数</span>
            </div>
          </div>
          <div class="title">详情</div>
          <div class="table_box">
            <el-table stripe :data="numsForm.familyMaintainList" v-loading="loading" height="500">
              <el-table-column label="村/组" align="center" prop="managementFull" />
              <el-table-column label="门牌号/商铺名" align="center" prop="storehouseNumber" width="120" />
              <el-table-column label="户主" align="center" prop="famName" />
              <el-table-column label="流动人口" align="center" prop="famCountWai" />
              <el-table-column label="总人口" align="center" prop="famCount" />
              <el-table-column label="" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <img src="../assets/img/r-arrow.png" alt="" @click.stop="handleShow(scope.row)" style="width:30px;height:30px;cursor: pointer;" />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
  <!-- </dv-border-box-1> -->
    </div>
    <wg-mode ref="wgMode"></wg-mode>
  </div>
</template>

<script>
import WgMode from './wgMode.vue'
import { getDangIndex, getResidentDetail, getResidents } from '@/api/api.js'
import { Loading } from 'element-ui'
export default {
  components: { WgMode },
  data() {
    return {
      show: false,
      word: '',
      imgList: [],
      title: '',
      paramsData: '',
      lx: '',
      tableList: [],
      content: '',
      numsForm: { familyNum: 0, residentNum: 0, flowNum: 0 },
      optDspLineList: [],
      itemtype: '',
      loading: true
    }
  },
  methods: {
    initData(data) {
      console.log(data)
      this.loading = true
      this.paramsData = data
      //更新党支部人员基本情况
      getDangIndex({ branchId: data.id }).then(res => {
        let memberCount = res.model.memberCount
        this.$set(this.paramsData, 'formalMember', memberCount.formalMember)
        this.$set(this.paramsData, 'prepareMember', memberCount.prepareMember)
        this.$set(this.paramsData, 'developMember', memberCount.developMember)
        this.$set(this.paramsData, 'flowMember', memberCount.flowMember)
        this.$set(this.paramsData, 'bedMember', memberCount.bedMember)
        this.$set(this.paramsData, 'armyMember', memberCount.armyMember)
      })
      this.title = data.name
      this.word = data.content
      this.content = data.articleContent || data.introduction || data.word
      // this.imgList = data.imgList;
      this.show = true
      this.lx = data.elementType ? data.elementType : ''
      if ((data?.type && data.type == 'gridType') || this.itemtype) {
        // this.getallResident(data.pid ? '' : data.villageId, data.id)
        //传值修改
        console.log(data)

        this.getallResident(data.id, data.villageId, data.ids)
      }
      this.loading = false
      // this.tableList = data.tableList;
    },
    close() {
      this.show = false
      this.optDspLineList = []
      this.paramsData = {}
      this.itemtype = ''
    },
    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },

    getallResident(pid, villageId, ids) {
      this.loading = true
      getResidents({ pid: pid, villageId: villageId, ids: ids }).then(res => {
        if (res.code == '0') {
          console.log(res.model)
          if (res.model.length) {
            res.model.map(resmode => {
              if (resmode.familyMaintainList.length) {
                resmode.familyMaintainList.map(i => {
                  i.managementFull = i.managementName + i.houseNumber
                  i.storehouseNumber = i?.store ? i.store : i.houseNumber
                })
              }
            })
          }
          this.numsForm = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cummode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);

  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;
  .cun_inner {
    width: 90%;
  // background: linear-gradient(90deg, #0D1836 0%, rgba(13, 24, 54, 0.55) 100%);
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);


    position: absolute;
    left: 5%;
    top: 10%;
    z-index: 1000;
    padding: 20px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    .main_title {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
    }
    .title {
      font-size: 22px;
      color: #fff;
      text-align: left;
      padding: 10px 20px;
      .cun_num {
        flex: 1;
        text-align: center;
      }
    }
    .se_title {
      margin: 10px 0;
      font-size: 22px;
    }
    .box {
      // padding: 0 20px;
      // max-height: 490px;
      // overflow-y: scroll;
      // margin-bottom: 20px;
      width: 90%;
      padding: 0 20px;
      max-height: 700px;
      overflow-y: scroll;
      margin: 30px auto;
      .party_nums {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 8px auto;
        .party_item {
          flex: 1;
        }
        .party_title {
          display: inline-block;
          width: 58%;
          text-align: right;
        }
      }
      .new_title {
        padding: 10px 0;
        font-weight: 800;
        margin: 20px auto 0;
      }
      .word {
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        // text-indent: 2em;
        video {
          width: 180px;
          height: 180px;
        }
        img {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
      }
      .word /deep/ img {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ video {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ table {
        width: 100% !important;
        box-sizing: border-box !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;
        border-spacing: 0px !important;
        tbody {
          border-spacing: 0px !important;
        }
        tbody tr:nth-child(1) {
          text-align: center !important;
        }
        th {
          padding: 4px !important;
          font-size: 15px !important;
          font-weight: bold !important;
          border: 1px solid grey !important;
        }
        tr {
          border-color: inherit !important;
          border: 1px solid grey !important;
        }
        td {
          padding: 4px !important;
          font-size: 14px !important;
          border-top: 1px solid grey !important;
          border-left: 1px solid grey !important;
          text-align: center !important;
        }
      }

      .imgbox {
        margin-top: 20px;
        flex-wrap: wrap;
        .video {
          width: 100%;
          height: 100%;
        }
        .img_item {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .table_box {
      height: auto;
      padding: 0 10px 20px;
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 10000;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
    .nclose {
      font-size: 42px;
      right: 25px;
      top: 15px;
    }
  }
}
.box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}
.box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
.dyth {
  font-size: 14px;
  text-align: center;
  padding: 5px;
  color: red;
}
.dytd {
  text-align: center;
  padding: 5px;
}
.redcolor {
  color: red !important;
}
.jieimg {
  max-width: 100%;
  height: auto;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}
.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}
.el-table tr {
  background-color: transparent !important;
}
.el-table .cell {
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  color: #fff;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
